import { type App, defineAsyncComponent } from 'vue'

export const importGlobalComponent = (app: App) => {
  // PromotionEntryWrap
  app.component(
    'PromotionEntryWrap',
    defineAsyncComponent(() => import('@/ts/components/picker/PromotionEntryWrap.vue'))
  )

  // IndustryPickerWrap component
  app.component(
    'IndustryPickerWrap',
    defineAsyncComponent(
      () => import('@/ts/components/picker/industryPicker/IndustryPickerWrap.vue')
    )
  )

  // IndustrySingleFormWrap component
  app.component(
    'IndustrySingleFormWrap',
    defineAsyncComponent(
      () => import('@/ts/components/picker/industrySingleForm/IndustrySingleFormWrap.vue')
    )
  )
}
