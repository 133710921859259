import { createApp, provide, h } from 'vue'
import { createHead } from '@unhead/vue'
import { createPinia } from 'pinia'
import { createGmapVuePlugin } from '@gmap-vue/v3'
import * as Sentry from '@sentry/vue'
import router from '@/ts/routes'
import { apolloClient } from '@/ts/plugins/apolloClient'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { DefaultApolloClient } from '@vue/apollo-composable'
import { createUserWay } from '@/ts/plugins/accessibility'
import { createCookieConsent } from '@/ts/plugins/gdprCookies'
import { importGlobalComponent } from '@/ts/services/global/importGlobalComponent'
import i18n from '@/ts/plugins/i18n'
import App from '@/ts/App.vue'

import 'vue3-carousel/dist/carousel.css'
import 'sweetalert2/src/sweetalert2.scss'
import '@gmap-vue/v3/dist/style.css'

// import font Awesome library
import '@/ts/plugins/fontAwsome'

import '@/css/app.scss'
import { isAxiosError } from 'axios'
import { isBot, isPrerender } from '@/ts/services/variables/isbot'

const pinia = createPinia()
const head = createHead()

if (!isPrerender && !isBot) {
  createUserWay()
  createCookieConsent()
}

const app = createApp({
  setup() {
    provide(DefaultApolloClient, apolloClient)
  },
  render: () => h(App)
})

app.use(router)
app.use(head)
app.use(i18n)
app.use(pinia)
app.use(
  createGmapVuePlugin({
    load: {
      key: import.meta.env.VITE_GOOGLE_MAP_API_KEY,
      libraries: 'map',
      mapIds: ['4ac3ebaada8f7ad0']
    }
  })
)

importGlobalComponent(app)
app.component('FontAwesomeIcon', FontAwesomeIcon)

if (import.meta.env.PROD && !isBot) {
  Sentry.init({
    app,
    dsn: 'https://e90b2744e42f94c5d93986ff919a74c4@o4507088677306368.ingest.us.sentry.io/4507581458874368',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
      Sentry.thirdPartyErrorFilterIntegration({
        filterKeys: ['sar-frontend-js'],
        behaviour: 'drop-error-if-contains-third-party-frames'
      })
    ],
    // Performance Monitoring
    tracesSampleRate: 0.6,
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [import.meta.env.VITE_APP_URL],
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 0.1,
    ignoreErrors: [
      'The Google Maps JavaScript API could not load.',
      /^Failed to fetch dynamically imported module/,
      /Apollo/,
      /Meta Pixel/,
      /Unable to preload CSS for/
    ],
    beforeSend(event, hint) {
      if (hint.originalException && isAxiosError(hint.originalException)) {
        if (hint.originalException?.response?.status === 422) {
          return null
        }
      }
      return event
    }
  })
}

app.mount('#app')
