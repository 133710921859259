import { createRouter, createWebHistory, type RouteRecordRaw } from 'vue-router'
import { useStorage } from '@vueuse/core'
import { locationRoutes } from '@/ts/routes/children/Location'
import { useSharePageRouter } from '@/ts/routes/sharePageRouter'
import { blogPath, storeListPath, storePath } from '@/ts/services/variables/countryVariable'
import { useStoreSetting } from '@/ts/routes/middlewares/storeSetting'
import { useUserTrackingData } from '@/ts/routes/middlewares/userTrackingData'
import { useWebsiteRedirect } from '@/ts/routes/middlewares/websiteRedirect'
import { analyticsIns } from '@/ts/services/analytics'

const routes: RouteRecordRaw[] = [
  {
    name: 'vendors.registration',
    path: '/vendor-registration',
    component: () => import('@/ts/pages/vendors/RegistrationPage.vue')
  },
  {
    path: '/about',
    component: () => import('@/ts/pages/about/AboutWrap.vue'),
    children: [
      {
        name: 'AboutRoot',
        path: ':about_slug',
        component: () => import('@/ts/pages/about/AboutRoot.vue')
      }
    ]
  },
  {
    props: true,
    name: 'StateLocations',
    path: '/state/:state',
    component: () => import('@/ts/pages/locations/LocationsPage.vue'),
    meta: {
      scrollToTop: false
    }
  },
  {
    path: `/${storeListPath}`,
    children: [
      {
        name: 'Locations',
        path: '',
        component: () => import('@/ts/pages/locations/LocationsPage.vue'),
        meta: {
          scrollToTop: false
        },
        beforeEnter: () => {
          sharePagesService.reset()
          sharePagesService.addRoutes(router)
        }
      }
    ]
  },
  {
    path: `/${storePath}`,
    children: [
      {
        path: ':slug',
        component: () => import('@/ts/layouts/LocationLayout.vue'),
        children: [
          locationRoutes,
          {
            name: 'customComponentPage',
            path: ':componentSlug',
            component: () => import('@/ts/pages/locations/CustomComponentPage.vue'),
            meta: {
              scrollToTop: false
            }
          }
        ],
        beforeEnter: (to) => {
          sharePagesService.reset(to.params.slug.toString())
          sharePagesService.addRoutes(router)
        }
      }
    ]
  },
  {
    name: 'FaqLists',
    path: '/faq',
    component: () => import('@/ts/pages/faq/faqPage.vue')
  },
  {
    name: 'FaqDetail',
    path: '/faq/:faq_slug',
    component: () => import('@/ts/pages/faq/faqDetailPage.vue')
  },
  {
    name: 'industryDetails',
    path: '/industries/:industry_name',
    component: () => import('@/ts/pages/industries/industryDetailPage.vue')
  },
  {
    name: 'BuyersGuides',
    path: '/buyers-guides',
    component: () => import('@/ts/pages/buyersGuides/BuyersGuidesPage.vue')
  },
  {
    path: '/signage-materials',
    name: 'SignageMaterials',
    component: () => import('@/ts/pages/signage/MaterialsPage.vue')
  },
  {
    path: '/signage-methods',
    name: 'SignageManufacturingMethods',
    component: () => import('@/ts/pages/signage/ManufacturingPage.vue')
  },
  {
    path: '/signage-methods-methods/:slug',
    name: 'SignageManufacturingMethodsDetail',
    component: () => import('@/ts/pages/signage/ManufacturingDetailPage.vue')
  },
  {
    path: '/promotions',
    children: [
      {
        name: 'promotionDetail',
        path: ':promotion_slug',
        component: () => import('@/ts/pages/promotion/PromotionDetailPage.vue')
      }
    ]
  },
  {
    path: `/${blogPath}`,
    children: [
      {
        name: 'blogList',
        path: '',
        component: () => import('@/ts/pages/blogs/BlogListPage.vue')
      },
      {
        name: 'blogDetail',
        path: 'article/:article_slug',
        component: () => import('@/ts/pages/blogs/BlogDetailPage.vue')
      }
    ]
  },
  {
    name: 'customPage',
    path: '/page/:customPageSlug',
    component: () => import('@/ts/pages/CustomPage.vue')
  },
  {
    name: 'customPageThankYou',
    path: '/page/:customPageSlug/thank-you',
    component: () => import('@/ts/pages/CustomThankYouPage.vue')
  },
  {
    name: '404',
    path: '/404',
    component: () => import('@/ts/pages/404Page.vue')
  },
  {
    name: 'catchAll',
    path: '/:pathMatch(.*)*',
    component: () => import('@/ts/pages/CatchAll.vue')
  }
]

const router = createRouter({
  // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
  history: createWebHistory(),
  routes, // short for `routes: routes`
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth'
      }
    }

    if (to.meta.scrollToTop === false) {
      return {}
    }

    if (savedPosition) {
      return savedPosition
    }

    return { top: 0 }
  }
})

const locationStorage = useStorage<{ slug: string | null | undefined }>('location_info', {
  slug: undefined
})

// initial
const sharePagesService = useSharePageRouter(locationStorage.value.slug)
sharePagesService.addRoutes(router)

router.beforeEach(useStoreSetting().middleware)
router.beforeEach(useUserTrackingData().middleware)
router.beforeEach(useWebsiteRedirect().middleware)
router.beforeEach((to, from, next) => {
  window.prerenderReady = false
  next()
})

router.afterEach(() => {
  window.prerenderReady = true
  analyticsIns.value?.page()
})
export default router
